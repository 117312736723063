@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;900&display=swap");

/* preflight.css */
*,
::before,
::after {
	box-sizing: border-box;
	border-width: 0;
	border-style: solid;
	border-color: currentcolor;
}

html {
	line-height: 1.5;
	text-size-adjust: 100%;
	tab-size: 4;
	font-family: system-ui;
}

body {
	margin: 0;
	line-height: inherit;
}

hr {
	height: 0;
	color: inherit;
	border-top-width: 1px;
}

abbr:where([title]) {
	text-decoration: underline dotted;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-size: inherit;
	font-weight: inherit;
}

a {
	color: inherit;
	text-decoration: inherit;
}

b,
strong {
	font-weight: bolder;
}

code,
kbd,
samp,
pre {
	font-family:
		"fontFamily.mono",
		ui-monospace,
		SFMono-Regular,
		Menlo,
		Monaco,
		Consolas,
		"Liberation Mono",
		"Courier New",
		monospace;
	font-size: 1em;
}

small {
	font-size: 80%;
}

sub,
sup {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
}

sub {
	bottom: -0.25em;
}

sup {
	top: -0.5em;
}

table {
	text-indent: 0;
	border-color: inherit;
	border-collapse: collapse;
}

button,
input,
optgroup,
select,
textarea {
	font-family: inherit;
	font-size: 100%;
	font-weight: inherit;
	line-height: inherit;
	color: inherit;
	margin: 0;
	padding: 0;
}

button,
select {
	text-transform: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
	appearance: button;
	background-color: transparent;
	background-image: none;
}

:-moz-focusring {
	outline: auto;
}

:-moz-ui-invalid {
	box-shadow: none;
}

progress {
	vertical-align: baseline;
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
	height: auto;
}

[type="search"] {
	appearance: textfield;
	outline-offset: -2px;
}

::-webkit-search-decoration {
	appearance: none;
}

::-webkit-file-upload-button {
	appearance: button;
	font: inherit;
}

summary {
	display: list-item;
}

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
	margin: 0;
}

fieldset {
	margin: 0;
	padding: 0;
}

legend {
	padding: 0;
}

ol,
ul,
menu {
	list-style: none;
	margin: 0;
	padding: 0;
}

textarea {
	resize: vertical;
}

input::placeholder,
textarea::placeholder {
	opacity: 1;
	color: #9ca3af;
}

button,
[role="button"] {
	cursor: pointer;
}

:disabled {
	cursor: default;
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
	display: block;
	vertical-align: middle;
}

img,
video {
	max-width: 100%;
	height: auto;
}

.Typewriter {
	display: inline;
}
.Typewriter__wrapper {
	font-weight: 400;
	font-style: italic;
}

@media (max-width: 400px) {
	.Typewriter {
		display: block;
	}
}